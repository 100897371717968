import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>I love you baby 😍👸 </h2>
      </header>
    </div>
  );
}

export default App;
